/* SaveNotification.css */

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.slideDown {
  animation: slideDown 0.5s ease-out;
}

.slideUp {
  animation: slideUp 0.5s ease-out;
}
